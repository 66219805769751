import React, { ReactNode } from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';

import Header from '../components/Header';
import Page from '../components/Page';

import { getUser, UserContext } from '../hooks/useUser';
import Footer from '../components/Footer';
import { Button } from '@invertase/ui';

const RouterPage = (props: { render: JSX.Element } & RouteComponentProps) =>
  React.cloneElement(props.render, props);

function Purchase() {
  return (
    <div
      className="lg:flex flex items-center justify-center flex-col"
      style={{ minHeight: '25vh' }}
    >
      Notifee is now free and fully open-source, there is no need to purchase new licenses.
      <Button
        size="base"
        onClick={() => {
          window.location.href = 'https://invertase.io/blog/open-sourcing-notifee';
        }}
        className="mt-4 w-full md:w-auto"
      >
        Learn More &raquo;
      </Button>
    </div>
  );
}

function PurchasePage({ location }: { location: Location }) {
  const [loading, user] = getUser();

  const wrapper = (children: ReactNode | ReactNode[]) => (
    <UserContext.Provider value={user}>
      <Page
        location={location}
        title="Purchase License"
        className="bg-gray-100 dark:bg-gray-800"
        header={<Header search={false} />}
      >
        <div className="mt-12">{children}</div>
      </Page>
    </UserContext.Provider>
  );

  if (loading) {
    return wrapper(null);
  }

  if (!user) {
    return <Redirect noThrow to="/login" state={{ sendTo: location.pathname }} />;
  }

  return wrapper(
    <>
      <Router basepath="/purchase">
        <RouterPage path="/" render={<Purchase />} />
        <RouterPage path="/checkout" render={<Purchase />} />
        <RouterPage path="/complete" render={<Purchase />} />
        <RouterPage path="/cancelled" render={<Purchase />} />
      </Router>
      <Footer />
    </>,
  );
}

export default PurchasePage;
